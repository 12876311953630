import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { theme } from '../../config/website'
import SEO from "../components/SEO/SEO"

const Container = styled.div`
  a {
    cursor: pointer;
    text-decoration: none;
    color: ${theme.primaryColor};
    font-family: Bariol-Bold;

    :hover, :active {
      color: inherit;
      text-decoration: underline;
    }

    :visited {
      color: inherit;
    }
  }

  @media (min-width: 800px) {
    display: grid;
    grid-column-gap: 40px;
    grid-template-columns: 6.5fr 3.5fr;
    grid-template-rows: auto;
  }
`

const FeaturedImage = styled(Img)`
  margin-bottom: 16px;

  @media (min-width: 800px) {
    display: none;
  }
`

const ImageList = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    >:first-child {
      display: none;
    }
  }

  @media (min-width: 800px) {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  div:last-child {
    margin-bottom: 0px;
  }
`

const Image = styled(Img)`
  margin-bottom: 16px;
`

const TextContainer = styled.div`
  @media (min-width: 800px) {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-column-end: 3;
  }
`

const Title = styled.h1`
  margin-top: 0px;
  margin-bottom: 8px;
  font-family: 'Bariol-Bold';
`

const Subtitle = styled.h3`
  color: ${theme.secondaryColor};
  margin-top: 0px;
  margin-bottom: 16px;
  font-weight: normal;
  font-family: BariolRegular-Italic;
  letter-spacing: 1px;
`

const Content = styled.div``

const Project = ({ data: { prismicProject } }) => {
  const data = {
    images: prismicProject.data.images
      .filter(item => item.image.localFile != null)
      .map(item => item.image.localFile.childImageSharp.fluid),
    subtitleText: prismicProject.data.categories
      .filter(item => item.category != null)
      .map(item => item.category.document[0].data.name)
      .join(", "),
    titleText: prismicProject.data.title.text,
    contentHTML: prismicProject.data.content.html
  }
  const seo = {
    title: data.titleText,
    uid: prismicProject.uid,
    image: data.images.length ? prismicProject.data.images[0].image.localFile.publicURL : null
  }

  return (
    <Container>
      <SEO meta={seo} isProject />
      {(data.images.length ? <FeaturedImage fluid={data.images[0]} /> : "")}
      <TextContainer>
        <Title>{data.titleText}</Title>
        <Subtitle>{data.subtitleText}</Subtitle>
        <Content dangerouslySetInnerHTML={{ __html: data.contentHTML }} />
      </TextContainer>
      <ImageList>
        {data.images.map((image, index) => 
          <Image key={index} fluid={image} />
        )}
      </ImageList>
    </Container>
  )
}

export default Project

export const pageQuery = graphql`
  query ProjectBySlug($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        content {
          html
        }
        categories {
          category {
            document {
              data {
                name
              }
            }
          }
        }
        images {
            image {
              localFile {
                childImageSharp {
                    fluid(maxWidth: 1024, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                }
                publicURL
              }
            }
          }
      }
    }
  }
`